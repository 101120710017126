
.sportsman-page-ver {
    background-color: #142626;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.sportsman-page-ver .overlap-wrapper {
    background-color: #142626;
    border: 1px none;
    height: 1280px;
    overflow: hidden;
    width: 100%;
}

.sportsman-page-ver .overlap {
    background: linear-gradient(180deg, rgb(20, 38, 38) 0%, rgb(11, 20, 19) 100%);
    height: 1287px;
    left: -9px;
    position: relative;
    top: -7px;
    width: 100%;
}

.sportsman-page-ver .side-bar {
    height: 34px;
    left: 45px;
    position: absolute;
    top: 2%;
    width: 314px;
}

.sportsman-page-ver .text-wrapper11 {
    color: #f94515;
    font-family: "Druk Wide Bold-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 312px;
}

.sportsman-page-ver .overlap-group-sp {
    height: 100%;
    left: 9px;
    position: absolute;
    top: 8%;
    width: 100%;
}

.sportsman-page-ver .rectangle-wrapper {
    background-color: #ffffff6e;
    height: 5px;
    left: 0;
    position: absolute;
    top: 268px;
    width: 483px;
}

.sportsman-page-ver .rectangle {
    background-color: #f94515;
    height: 5px;
    width: 275px;
}

.sportsman-page-ver .xavi-simons {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 83px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    text-shadow: 0px 1px 3px #00000040;
    top: 39px;
    width: 88px;
}

.sportsman-page-ver .group {
    height: 53px;
    left: 21px;
    position: absolute;
    top: 21px;
    width: 53px;
}

.sportsman-page-ver .overlap-group-2 {
    border-radius: 46px;
    height: 53px;
    position: relative;
}

.sportsman-page-ver .group-wrapper {
    background-color: #000000;
    border-radius: 46px;
    height: 47px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 47px;
}

.sportsman-page-ver .img {
    height: 18px;
    left: 16px;
    position: absolute;
    top: 14px;
    width: 16px;
}

.sportsman-page-ver .rectangle-2 {
    border: 1px solid;
    border-color: #f94515;
    border-radius: 46px;
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 53px;
}

.sportsman-page-ver .group-2 {
    height: 30px;
    left: 21px;
    position: absolute;
    top: 222px;
    width: 437px;
}

.sportsman-page-ver .overlap-2 {
    height: 37px;
    left: 21px;
    position: absolute;
    top: 286px;
    width: 364px;
}

.sportsman-page-ver .text {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 93px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 10px;
    width: 1px;
}

.sportsman-page-ver .xavi-simons-special {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 22px;
    width: 364px;
}

.sportsman-page-ver .span {
    font-weight: 800;
}

.sportsman-page-ver .text-wrapper-2 {
    font-family: "Bambusa_tst5-Regular", Helvetica;
}

.sportsman-page-ver .liked-by {
    color: #bebebe;
    font-family: "Avenir-Light", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 142px;
}

.sportsman-page-ver .text-wrapper-3 {
    font-weight: 300;
}

.sportsman-page-ver .text-wrapper-4 {
    font-family: "Avenir-Black", Helvetica;
    font-weight: 900;
}

.sportsman-page-ver .overlap-group-wrapper {
    height: 336px;
    left: 659px;
    position: absolute;
    top: 253px;
    width: 493px;
}

.sportsman-page-ver .overlap-3 {
    height: 346px;
    left: -15px;
    position: relative;
    top: -10px;
    width: 515px;
}

.sportsman-page-ver .text-2 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 128px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 304px;
    width: 1px;
}

.sportsman-page-ver .rectangle-3 {
    background-color: #ffffff6e;
    height: 5px;
    left: 15px;
    position: absolute;
    top: 276px;
    width: 485px;
}

.sportsman-page-ver .rectangle-4 {
    border: 1px solid;
    border-color: #f94515;
    border-radius: 46px;
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 53px;
}

.sportsman-page-ver .daniel-santiago {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 36px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 316px;
    width: 440px;
}

.sportsman-page-ver .p {
    color: #bebebe;
    font-family: "Avenir-Light", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 36px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 294px;
    width: 134px;
}

.sportsman-page-ver .daniel-santiago-2 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 98px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 46px;
    width: 124px;
}

.sportsman-page-ver .div-wrapper {
    height: 53px;
    left: 36px;
    position: absolute;
    top: 28px;
    width: 53px;
}

.sportsman-page-ver .group-3 {
    height: 30px;
    left: 36px;
    position: absolute;
    top: 229px;
    width: 437px;
}

.sportsman-page-ver .video-post-3 {
    height: 336px;
    left: 659px;
    position: absolute;
    top: 639px;
    width: 493px;
}

.sportsman-page-ver .overlap-4 {
    height: 943px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.sportsman-page-ver .guillaume-de-germain {
    height: 200px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.sportsman-page-ver .search-hover-main {
    height: 80%;
    left: 36px;
    position: absolute;
    top: 131px;
    width: 450px;
}

.sportsman-page-ver .overlap-5 {
    background-color: #0c1514;
    border-radius: 16px;
    box-shadow: 0px 12px 12px #000000b0;
    height: 100%;
    position: relative;
    width: 450px;
}

.sportsman-page-ver .overlap-6 {
    height: 160px;
    left: 51px;
    position: absolute;
    top: 236px;
    width: 362px;
}

.sportsman-page-ver .born {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 111px;
    width: 86px;
}

.sportsman-page-ver .text-wrapper-5 {
    color: #cfcfcf;
    line-height: 18px;
}

.sportsman-page-ver .text-wrapper-6 {
    color: #e2e2e2;
    font-family: "Montserrat Bambussa-ExtraBold", Helvetica;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
}

.sportsman-page-ver .height {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 126px;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 111px;
    width: 86px;
}

.sportsman-page-ver .weight {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 252px;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 111px;
    width: 86px;
}

.sportsman-page-ver .bio-lorem-ipsum-is {
    color: #e2e2e2;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 0;
    width: 362px;
}

.sportsman-page-ver .line {
    height: 1px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 362px;
}

.sportsman-page-ver .current-team-inter {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 51px;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 409px;
    width: 364px;
}

.sportsman-page-ver .leagues {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 51px;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 473px;
    width: 364px;
}

.sportsman-page-ver .supercup {
    color: transparent;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 51px;
    letter-spacing: -0.08px;
    line-height: 12px;
    position: absolute;
    top: 536px;
    width: 364px;
}

.sportsman-page-ver .line-2 {
    height: 1px;
    left: 51px;
    position: absolute;
    top: 400px;
    width: 362px;
}

.sportsman-page-ver .line-3 {
    height: 1px;
    left: 51px;
    position: absolute;
    top: 464px;
    width: 362px;
}

.sportsman-page-ver .line-4 {
    height: 1px;
    left: 51px;
    position: absolute;
    top: 528px;
    width: 362px;
}

.sportsman-page-ver .line-5 {
    height: 1px;
    left: 51px;
    position: absolute;
    top: 592px;
    width: 362px;
}

.sportsman-page-ver .group-4 {
    height: 32px;
    left: 51px;
    position: absolute;
    top: 633px;
    width: 364px;
}

.sportsman-page-ver .text-wrapper-9 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-ExtraBold", Helvetica;
    font-size: 16px;
    font-weight: 800;
    left: 0;
    letter-spacing: -0.08px;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 86px;
}

.sportsman-page-ver .overlap-group-3 {
    height: 6px;
    left: 0;
    position: absolute;
    top: 26px;
    width: 362px;
}

.sportsman-page-ver .line-6 {
    height: 1px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 362px;
}

.sportsman-page-ver .line-7 {
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 212px;
}

.sportsman-page-ver .group-5 {
    height: 32px;
    left: 51px;
    position: absolute;
    top: 677px;
    width: 364px;
}

.sportsman-page-ver .group-6 {
    height: 21px;
    left: 189px;
    position: absolute;
    top: 763px;
    width: 85px;
}

.sportsman-page-ver .frame {
    height: 18px;
    left: -15px;
    position: relative;
    top: -60px;
    width: 65px;
}

.sportsman-page-ver .text-wrapper-10 {
    color: #9e9e9e;
    font-family: "Bambusa_tst5-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: -1px;
    white-space: nowrap;
    width: 90px;
}

.sportsman-page-ver .line-8 {
    height: 2px;
    left: -20px;
    object-fit: cover;
    position: relative;
    top: -60px;
    width: 84px;
}

.profile-icon {
    height: 55%;
    left: 30%;
    top: 20%;
    position: relative;
    width: 64px;
    margin: 0 1px;
}

.profile-icon img:hover {
    cursor: pointer;
}

.sportsman-page-ver .top-star-icon {
    background-image: url(https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/vector-1.svg);
    background-size: 100% 100%;
    height: 59px;
    left: 425px;
    position: absolute;
    top: 131px;
    width: 44px;
}

.sportsman-page-ver .star {
    height: 26px;
    left: 9px;
    position: absolute;
    top: 14px;
    width: 27px;
}

.sportsman-page-ver .upload {
    height: 27px;
    left: 1830px;
    position: absolute;
    top: 154px;
    width: 24px;
}

.sportsman-page-ver .search-bar {
    height: 53px;
    left: 374px;
    position: absolute;
    top: 40px;
    width: 988px;
}

.sportsman-page-ver .overlap-7 {
    border-radius: 14px;
    height: 53px;
    position: relative;
    width: 986px;
}

.sportsman-page-ver .text-wrapper-11 {
    color: #e2e2e280;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 17px;
    white-space: nowrap;
}

.sportsman-page-ver .rectangle-5 {
    border: 2px solid;
    border-color: #363e3d;
    border-radius: 14px;
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 986px;
}

.sportsman-page-ver .vector {
    height: 25px;
    left: 934px;
    position: absolute;
    top: 14px;
    width: 25px;
}

.sportsman-page-ver .img-wrapper {
    background-color: #f94515;
    border: 2px solid;
    border-color: #363e3d;
    border-radius: 32px;
    height: 64px;
    left: 1805px;
    position: absolute;
    top: 34px;
    width: 64px;
}

.sportsman-page-ver .group-7 {
    height: 25px;
    left: 20px;
    position: absolute;
    top: 18px;
    width: 21px;
}

.sportsman-page-ver .overlap-8 {
    height: 61px;
    position: relative;
    width: 280px;
}
.upload-button {
    position: relative;
    top: 29px;
    height: 40%;
    margin: 0 1px;
}
.navbar .overlap-10 {
    height: 61px;
    position: relative;
    width: 20%;
}

.sportsman-page-ver .group-8 {
    height: 61px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
}

.sportsman-page-ver .overlap-group-4 {
    background-color: #363e3d;
    border-radius: 16px;
    box-shadow: 0px 4px 12px #00000040;
    height: 61px;
    position: relative;
    width: 276px;
}

.sportsman-page-ver .text-wrapper-12 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 22px;
    white-space: nowrap;
}

.sportsman-page-ver .text-wrapper-13 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 27px;
    font-weight: 400;
    left: 245px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 21px;
    white-space: nowrap;
}

.sportsman-page-ver .vector-2 {
    height: 22px;
    left: 27px;
    position: absolute;
    top: 20px;
    width: 34px;
}

.sportsman-page-ver .line-9 {
    height: 1px;
    left: 9px;
    position: absolute;
    top: 126px;
    width: 1920px;
}

.navbar {
    height: 8%;
    left: 55%;
    position: absolute;
    top: 7px;;
    width: 83%;
    display: flex;
    flex-direction: row;
}

.notification-icon {
    height: 25px;
    top: 37%;
    left: 28%;
    position: relative;
    width: 30px;
    margin: 1px;
}

.group-17:hover .overlap-upload {
    background-color: #5BA7A1;
    cursor: pointer;
}

.group-17 {
    height: 61px;
    left: 0;
    position: relative;
    top: 0;
    width: 180px;
}
.overlap-upload {
    background-color: #363e3d;
    border-radius: 16px;
    box-shadow: 0px 4px 12px #00000040;
    height: 61px;
    position: relative;
    width: 100%;
}

.vector2:hover .overlap-upload {
    background-color: #5BA7A1;
    cursor: pointer;
}

.navbar text-wrapper-7 {

    color: #e2e2e2;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 22px;
    white-space: nowrap;
}
.sportsman-page-ver .main-space {
    height: 100%;
    left: 460px;
    position: absolute;
    top: 184px;
    width: 73%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    overflow:hidden;
    overflow-y:scroll;
}
.sportsman-page-ver .div {
    height: fit-content;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}
.sportsman-page-ver .title-saved{
    color: #E2E2E2;
    position: absolute;
    width: 100%;
}
.sportsman-page-ver .line-saved{
    color: #9E9E9E;
    bottom: 20px;
    position: absolute;
    width: 80%;
}

.sportsman-page-ver .video-post {
    height: 350px;
    position: relative;
    margin: 5px;
    top: 25px;
}
.text-wrapper-88 {
    color: #E2E2E2;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 27px;
    font-weight: 400;
    letter-spacing:2px;
    left: 10%;
    line-height: 25px;
    width: 10px;
    position: relative;
    top: 13%;
}

.sportsman-page-ver .text-wrapper-8 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: -0.08px;
    left: 80%;
    line-height: 18px;
    width: 10px;
    position: absolute;
    top: 21px;
}
.sportsman-page-ver .cover-upload {
    width: 30px;
    position: absolute;
    height: 30px;
    right: 60px;
    bottom: -175px;
}
.sportsman-page-ver .cover-photo {
    position: relative;
}
.cover-upload:hover {
    cursor: pointer;
}

.sportsman-page-ver .input-text {
    background: transparent;
    border: 1px solid #657865;
    border-radius: 20px;
    color: #e0effe;
    width: 100%;
}

.edit-profile:hover {
    cursor: pointer;
    color: #5BA7A1;
}
.sportsman-page-ver ul {
    list-style-type: none;
}

.sportsman-page-ver .overlap-group {
    height: 40px;
    left: 23px;
    top: 80%;
    position: relative;
    width: 260px;
}

.sportsman-page-ver .austin-reaves {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 85px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 37px;
    width: 106px;
}
.sportsman-page-ver .video {
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    width:100%;
    height:100vh;
}
.sportsman-page-ver .austin-reaves-texas {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: relative;
    top: 22px;
    width: 266px;
}
.sportsman-page-ver .text-wrapper {
    font-weight: 800;
}