.buttons {
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 352px;
}

.buttons .icon-google-original-instance {
  height: 20px !important;
  left: unset !important;
  margin-bottom: -2px !important;
  margin-top: -2px !important;
  position: relative !important;
  top: unset !important;
  width: 20px !important;
}

.buttons .login-now {
  font-family: var(--body-large-semibold-font-family);
  font-size: var(--body-large-semibold-font-size);
  font-style: var(--body-large-semibold-font-style);
  font-weight: var(--body-large-semibold-font-weight);
  letter-spacing: var(--body-large-semibold-letter-spacing);
  line-height: var(--body-large-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.buttons.secondary {
  background-color: #e0effe;
  gap: 8px;
}

.buttons.primary {
  background-color: var(--azure-700);
  gap: 5px;
}

.buttons.secondary .login-now {
  color: var(--primary);
  margin-top: -2px;
}

.buttons.primary .login-now {
  color: var(--white);
  margin-top: -1px;
}
