.profile-box {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 1;
}

.profile-box-gone {
    z-index: -1;
}

.profile-box .search-hover-wrapper {
    border: 0px none;
    height: 333px;
    width: 346px;
}

.profile-box .search-hover {
    height: 333px;
    left:-40%;
    position: relative;
    top: 0;
    width: 352px;
}

.profile-box .overlap-group-box {
    background-color: #0c1514;
    border-radius: 16px;
    box-shadow: 0px 12px 12px #000000b0;
    height: 100%;
    position: relative;
    width: 100%;
}

.profile-box .my-videos-likes {
    color: transparent;
    font-family: "Montserrat Bambussa-ExtraBold", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 125px;
    width: 268px;
}

.profile-box .text-wrapper-box {
    color: #e2e2e2;
    font-weight: 800;
}

.profile-box .span {
    color: #cfcfcf;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
}

.profile-box .pp {
    color: #5ba7a1;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 57px;
    width: 268px;
}

.profile-box .nnn{
    color: white;
}

.profile-box .divv {
    color: #e2e2e2;
    font-family: "Montserrat Bambussa-ExtraBold", Helvetica;
    font-size: 16px;
    font-weight: 800;
    left: 38px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 27px;
    white-space: nowrap;
    width: 280px;
}

.hvr-box:hover {
    color: #5BA7A1;
    cursor: pointer;
}

.profile-box .line {
    height: 1px;
    left: 38px;
    position: absolute;
    top: 102px;
    width: 268px;
}
