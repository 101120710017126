.home-page {
    background-color: #142626;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.home-page .overlap-wrapper {
    background-color: #142626;
    border: 1px none;
    height: 1280px;
    overflow: hidden;
    width: 100%;
}

.home-page .overlap {
    height: 1456px;
    position: relative;
    width: 100%;
}

.home-page .rectangle {
    background: linear-gradient(180deg, rgb(20, 38, 38) 0%, rgb(11, 20, 19) 100%);
    height: 1287px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.home-page .video-post {
    height: 350px;
    position: relative;
    margin: 5px;
}

.home-page .overlap-group {
    height: 40px;
    left: 23px;
    top: 80%;
    position: relative;
    width: 260px;
}

.home-page .text {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 93px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: relative;
    top: 10px;
    width: 1px;
}

.home-page .austin-reaves-texas {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: relative;
    top: 22px;
    width: 266px;
}
.multiline-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.home-page .text-wrapper {
    font-weight: 800;
}

.home-page .span {
    font-family: "Bambusa_tst5-Regular", Helvetica;
}

.home-page .liked-by {
    color: #bebebe;
    font-family: "Avenir-Light", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: -10px;
    width: 134px;
}

.home-page .text-wrapper-2 {
    font-weight: 300;
}

.home-page .text-wrapper-3 {
    font-family: "Avenir-Black", Helvetica;
    font-weight: 900;
}

.home-page .div {
    height: fit-content;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}

.home-page .photo {
    height: 272px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 485px;
}

.home-page .rectangle-2 {
    background-color: #ffffff6e;
    height: 5px;
    left: 0;
    position: absolute;
    top: 267px;
    width: 485px;
}

.home-page .rectangle-3 {
    background-color: #f94515;
    height: 5px;
    left: 0;
    position: absolute;
    top: 267px;
    width: 108px;
}

.home-page .austin-reaves {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 85px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 37px;
    width: 106px;
}

.home-page .group {
    height: 53px;
    left: 23px;
    position: absolute;
    top: 19px;
    width: 53px;
}

.home-page .overlap-group-2 {
    border-radius: 46px;
    height: 53px;
    position: relative;
}

.home-page .group-wrapper {
    background-color: #000000;
    border-radius: 46px;
    height: 47px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 47px;
}

.home-page .img {
    height: 18px;
    left: 16px;
    position: absolute;
    top: 14px;
    width: 16px;
}
.home-page .img-like {
    height: 20px;
    right: 16px;
    position: absolute;
    top: 14px;
    width: 20px;
}
.home-page .img-like:hover {
    cursor: pointer;
}
.home-page .img-save {
    height: 20px;
    right: 44px;
    position: absolute;
    top: 14px;
    width: 20px;
}
.home-page .img-save:hover {
    cursor: pointer;
}
.home-page .rectangle-4 {
    border: 1px solid;
    border-color: #f94515;
    border-radius: 46px;
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 53px;
}

.home-page .group-2 {
    height: 30px;
    left: 23px;
    position: absolute;
    top: 221px;
    width: 438px;
}

.home-page .video-post-2 {
    height: 322px;
    left: 374px;
    position: absolute;
    top: 149px;
    width: 493px;
}

.home-page .video-post-3 {
    height: 324px;
    left: 1378px;
    position: absolute;
    top: 535px;
    width: 491px;
}

.home-page .overlap-2 {
    /*background-image: url(https://generation-sessions.s3.amazonaws.com/a357db0338555a33d696d99755e6b077/img/screenshot-2022-12-14-at-8-42-1-1@2x.png);*/
    background-position: 50% 50%;
    background-size: cover;
    height: 273px;
    left: 0;
    position: absolute;
    top: 0;
    width: 483px;
}

.home-page .rectangle-wrapper {
    background-color: #ffffff6e;
    height: 5px;
    left: 0;
    position: absolute;
    top: 268px;
    width: 483px;
}

.home-page .rectangle-5 {
    background-color: #f94515;
    height: 5px;
    width: 275px;
}

.home-page .xavi-simons {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 83px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    text-shadow: 0px 1px 3px #00000040;
    top: 39px;
    width: 88px;
}

.home-page .overlap-group-wrapper {
    height: 53px;
    left: 21px;
    position: absolute;
    top: 21px;
    width: 53px;
}

.home-page .group-3 {
    height: 30px;
    left: 21px;
    position: absolute;
    top: 222px;
    width: 437px;
}

.home-page .overlap-3 {
    height: 37px;
    left: 21px;
    position: absolute;
    top: 286px;
    width: 364px;
}

.home-page .xavi-simons-special {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 22px;
    width: 364px;
}

.home-page .p {
    color: #bebebe;
    font-family: "Avenir-Light", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 142px;
}

.home-page .video-post-4 {
    height: 324px;
    left: 1378px;
    position: absolute;
    top: 149px;
    width: 491px;
}

.home-page .video-post-5 {
    height: 336px;
    left: 875px;
    position: absolute;
    top: 535px;
    width: 493px;
}

.home-page .overlap-4 {
    /*background-image: url(https://generation-sessions.s3.amazonaws.com/a357db0338555a33d696d99755e6b077/img/jannik-skorna-my2zhbu6grk-unsplash-1-1@2x.png);*/
    background-position: 50% 50%;
    background-size: cover;
    height: 272px;
    left: 0;
    position: absolute;
    top: 0;
    width: 485px;
}

.home-page .div-wrapper {
    background-color: #ffffff6e;
    height: 5px;
    left: 0;
    position: absolute;
    top: 266px;
    width: 485px;
}

.home-page .rectangle-6 {
    background-color: #f94515;
    height: 5px;
    width: 175px;
}

.home-page .daniel-santiago {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 83px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 36px;
    width: 124px;
}

.home-page .group-4 {
    height: 53px;
    left: 21px;
    position: absolute;
    top: 18px;
    width: 53px;
}

.home-page .group-5 {
    height: 30px;
    left: 21px;
    position: absolute;
    top: 219px;
    width: 437px;
}

.home-page .overlap-5 {
    height: 53px;
    left: 21px;
    position: absolute;
    top: 284px;
    width: 440px;
}

.home-page .daniel-santiago-2 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 22px;
    width: 440px;
}

.home-page .video-post-6 {
    height: 336px;
    left: 875px;
    position: absolute;
    top: 149px;
    width: 493px;
}

.home-page .overlap-6 {
    height: 346px;
    left: -15px;
    position: relative;
    top: -10px;
    width: 515px;
}

.home-page .jannik-skorna {
    height: 302px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 515px;
}

.home-page .text-2 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 128px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 304px;
    width: 1px;
}

.home-page .rectangle-7 {
    background-color: #ffffff6e;
    height: 5px;
    left: 15px;
    position: absolute;
    top: 276px;
    width: 485px;
}

.home-page .rectangle-8 {
    background-color: #f94515;
    height: 5px;
    left: 15px;
    position: absolute;
    top: 276px;
    width: 175px;
}

.home-page .daniel-santiago-3 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 36px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 316px;
    width: 440px;
}

.home-page .liked-by-2 {
    color: #bebebe;
    font-family: "Avenir-Light", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 36px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 294px;
    width: 134px;
}

.home-page .daniel-santiago-4 {
    color: #ffffff;
    font-family: "Bambusa_tst5-ExtraBold", Helvetica;
    font-size: 10px;
    font-weight: 800;
    left: 98px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 46px;
    width: 124px;
}

.home-page .group-6 {
    height: 53px;
    left: 36px;
    position: absolute;
    top: 28px;
    width: 53px;
}

.home-page .group-7 {
    height: 30px;
    left: 36px;
    position: absolute;
    top: 229px;
    width: 437px;
}

.home-page .side-bar {
    height: 100%;
    position: fixed;
    width: 16%;
}

.home-page .navbar {
    height: 8%;
    left: 16%;
    position: absolute;
    top: 7px;;
    width: 83%;
    display: flex;
    flex-direction: row;
}

.main-space {
    height: 40%;
    left: 16%;
    position: absolute;
    top: 120px;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
}

.home-page .overlap-7 {
    height: 100%;
    position: relative;
    width: 100%;
}

.home-page .rectangle-9 {
    background-color: #0c1514;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.home-page .rectangle-10 {
    background-color: #353d3c;
    border-radius: 4px 0px 0px 4px;
    height: 64px;
    left: 341px;
    position: absolute;
    top: 101px;
    width: 5px;
}

.home-page .h-1 {
    color: #f94515;
    font-family: "Druk Wide Bold-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 36px;
    letter-spacing: -0.3px;
    line-height: normal;
    position: absolute;
    top: 42px;
    width: 312px;
}

.home-page .group-8 {
    height: 5%;
    left: 7%;
    width: 80%;
    position: relative;
    top: 10%;
    margin-bottom: 10px;
}

.home-page .overlap-upload {
    background-color: #363e3d;
    border-radius: 16px;
    box-shadow: 0px 4px 12px #00000040;
    height: 61px;
    position: relative;
    width: 100%;
}

.categories {
    height: 100%;
    position: relative;
    width: 80%;
}

.categories:hover {
    cursor: pointer;
}

.chosen-category {
    background-color: #363e3d;
    border-radius: 16px;
    box-shadow: 0px 4px 12px #00000040;
}
.categories div{
    color:  #545454 !important
}
.chosen-category div {
    color:  #E2E2E2 !important
}
.categories:hover div {
    color:  #E2E2E2 !important
}


.group-17:hover .overlap-upload {
    background-color: #5BA7A1;
    cursor: pointer;
}

.home-page .text-wrapper-4 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 32px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 21px;
    white-space: nowrap;
}

.home-page .text-wrapper-5 {
    color: #535353;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.home-page .group-10 {
    height: 18px;
    left: 62px;
    position: absolute;
    top: 266px;
    width: 55px;
}

.home-page .group-11 {
    height: 18px;
    left: 62px;
    position: absolute;
    top: 337px;
    width: 54px;
}

.home-page .group-12 {
    height: 18px;
    left: 62px;
    position: absolute;
    top: 408px;
    width: 36px;
}

.search-bar {
    height: 40%;
    left: 6%;
    position: relative;
    margin: 0 1px;
    top: 25%;
    width:60%;
}

.home-page .text-wrapper-6 {
    color: #e2e2e280;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 24px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 17px;
    white-space: nowrap;
    border: 0;
    background: transparent;
    border: 0;
    z-index: 100;
    width: 85%;
}

input:focus
{
    outline: none;
}

.home-page .rectangle-11 {
    border: 2px solid;
    border-color: #363e3d;
    border-radius: 14px;
    height: 53px;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}

.home-page .vector {
    height: 30px;
    top: -44px;
    left: 95%;
    position: relative;
    width: 25px;
}

.notification-icon {
    height: 25px;
    top: 37%;
    left: 28%;
    position: relative;
    width: 30px;
    margin: 1px;
}

.profile-icon {
    height: 55%;
    left: 30%;
    top: 20%;
    position: relative;
    width: 64px;
    margin: 0 1px;
}

.profile-icon img:hover {
    cursor: pointer;
}

.home-page .group-14 {
    height: 48px;
    left: 374px;
    position: absolute;
    top: 963px;
    width: 1487px;
}

.home-page .group-15 {
    height: 34px;
    left: 0;
    position: absolute;
    top: 14px;
    width: 141px;
}

.home-page .top {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 28px;
    font-weight: 800;
    left: 37px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
}

.home-page .football-icon {
    height: 24px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 24px;
}

.home-page .line {
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1487px;
}

.home-page .group-16 {
    height: 409px;
    left: 374px;
    position: absolute;
    top: 1047px;
    width: 1617px;
}

.home-page .overlap-9 {
    height: 409px;
    position: relative;
}

.home-page .top-bootball {
    height: 409px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1617px;
}

.home-page .mask-group {
    height: 240px;
    left: 0;
    position: absolute;
    top: 0;
    width: 256px;
}

.home-page .mask-group-2 {
    height: 240px;
    left: 272px;
    position: absolute;
    top: 0;
    width: 256px;
}
.video {
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    width:100%;
    height:100vh;
}
.main-space {
    overflow:hidden;
    overflow-y:scroll;
}

.home-page .gettyimages {
    height: 240px;
    left: 545px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 256px;
}

.home-page .mask-group-3 {
    height: 240px;
    left: 816px;
    position: absolute;
    top: 0;
    width: 256px;
}

.home-page .mask-group-4 {
    height: 240px;
    left: 1088px;
    position: absolute;
    top: 0;
    width: 256px;
}

.home-page .gettyimages-2 {
    height: 240px;
    left: 1361px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 194px;
}

.home-page .top-star-icon {
    /*background-image: url(https://generation-sessions.s3.amazonaws.com/a357db0338555a33d696d99755e6b077/img/vector-1-2.svg);*/
    background-size: 100% 100%;
    height: 52px;
    left: 473px;
    position: absolute;
    top: 0;
    width: 39px;
}

.home-page .star {
    height: 23px;
    left: 8px;
    position: absolute;
    top: 13px;
    width: 24px;
}

.home-page .star-wrapper {
    /*background-image: url(https://generation-sessions.s3.amazonaws.com/a357db0338555a33d696d99755e6b077/img/vector-1-2.svg);*/
    background-size: 100% 100%;
    height: 52px;
    left: 745px;
    position: absolute;
    top: 0;
    width: 39px;
}

.home-page .img-wrapper {
    /*background-image: url(https://generation-sessions.s3.amazonaws.com/a357db0338555a33d696d99755e6b077/img/vector-1.svg);*/
    background-size: 100% 100%;
    height: 52px;
    left: 201px;
    position: absolute;
    top: 0;
    width: 39px;
}

.fav-icon {
    height: 30px;
    left: 18%;
    top: 35%;
    position: relative;
    width: 23px;
    margin: 0 1px;
}

.upload-button {
    position: relative;
    top: 29px;
    height: 40%;
    margin: 0 1px;
}

.home-page .overlap-10 {
    height: 61px;
    position: relative;
    width: 20%;
}

.home-page .group-17 {
    height: 61px;
    left: 0;
    position: relative;
    top: 0;
    width: 180px;
}

.text-wrapper-7 {
    color: #e2e2e2;
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: -0.08px;
    line-height: 18px;
    position: absolute;
    top: 22px;
    white-space: nowrap;
}

.home-page .text-wrapper-8 {
    color: var(--e-2e-2e-2);
    font-family: "Montserrat Bambussa-Regular", Helvetica;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: -0.08px;
    left: 80%;
    line-height: 18px;
    width: 10px;
    position: absolute;
    top: 21px;
}

.home-page .vector-2 {
    height: 22px;
    left: 27px;
    position: absolute;
    top: 20px;
    width: 34px;
}

.vector2:hover .overlap-upload {
    background-color: #5BA7A1;
    cursor: pointer;
}

.fill-alert {
    width: 100%;
    height: 40px;
    text-align: center;
    position: fixed;
    flex-shrink: 0;
    background: linear-gradient(227deg, #5BA7A1 0%, rgba(91, 167, 161, 0.00) 92.00%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #E2E2E2;
    font-family:  Bambussa;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    line-height: 40px; /* 112.5% */
    letter-spacing: 0.078px;
    margin-top: 0px;
}
ul {
    list-style-type: none;
}



video {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px blue !important;
}
