.sign-up {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.sign-up .div {
  background-color: #ffffff;
  border: 1px none;
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sign-up .overlap-group {
  background: linear-gradient(180deg, rgb(20, 38, 38) 0%, rgb(11, 20, 19) 100%);
  height: 1287px;
  left: -9px;
  position: absolute;
  top: -7px;
  width: 50%;
}

.sign-up .one-space-where-you {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.44) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 56px;
  font-style: italic;
  font-weight: 500;
  height: 376px;
  left: 112px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  text-fill-color: transparent;
  top: 710px;
  width: 796px;
}

.sign-up .text-wrapper {
  color: #f94515;
  font-family: "Druk Wide Bold-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 112px;
  letter-spacing: -0.3px;
  line-height: normal;
  position: absolute;
  top: 119px;
  white-space: nowrap;
  width: 312px;
}

.sign-up .frame {
  height: 973px;
  left: 9px;
  position: absolute;
  top: 76px;
  width: 522px;
}

.sign-up .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left:  60%;
  position: absolute;
  top: 315px;
  width: 400px;
}

.sign-up .create-an-account {
  color: var(--textprimary);
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .div-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 12px;
left:  60%;
  position: absolute;
  top: 379px;
  width: 400px;
}

.sign-up .text-wrapper-2 {
  color: var(--textsecondary);
  flex: 1;
  font-family: var(--body-large-regular-font-family);
  font-size: var(--body-large-regular-font-size);
  font-style: var(--body-large-regular-font-style);
  font-weight: var(--body-large-regular-font-weight);
  letter-spacing: var(--body-large-regular-letter-spacing);
  line-height: var(--body-large-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.sign-up .input-text-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 47px;
left:  60%;
  position: absolute;
  top: 407px;
  width: 400px;
}

.sign-up .input-text {
  align-items: center;
  align-self: stretch;
  border: 3px solid;
  border-color: #bebebf;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5px;
  margin-bottom: -1.5px;
  margin-left: -1.5px;
  margin-right: -1.5px;
  margin-top: -1.5px;
  padding: 12px 16px;
  position: relative;
}

.sign-up .text-wrapper-3 {
  color: #5a5a5d;
  flex: 1;
  font-family: var(--body-medium-regular-font-family);
  font-size: var(--body-medium-regular-font-size);
  font-style: var(--body-medium-regular-font-style);
  font-weight: var(--body-medium-regular-font-weight);
  letter-spacing: var(--body-medium-regular-letter-spacing);
  line-height: var(--body-medium-regular-line-height);
  position: relative;
  white-space: nowrap;
  border: 0;
}
input {
  border: 0
}
.sign-up input:focus
{
  outline: none;
}

.sign-up .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  margin-top: -0.5px;
  position: relative;
}

.sign-up .frame-4 {
  align-items: flex-start;
  display: flex;
left:  60%;
  position: absolute;
  top: 478px;
  width: 400px;
}

.sign-up .input-text-2 {
  align-items: center;
  border: 3px solid;
  border-color: #bebebf;
  border-radius: 8px;
  display: flex;
  gap: 5px;
  left:  60%;
  padding: 12px 16px;
  position: absolute;
  top: 506px;
  width: 365px;
}
.bd-red {
  border-color: #f94515 !important;
}

.sign-up .content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.sign-up .content-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.sign-up .filled {
  align-self: stretch;
  color: var(--textcolordefault);
  font-family: var(--body-default-font-family);
  font-size: var(--body-default-font-size);
  font-style: var(--body-default-font-style);
  font-weight: var(--body-default-font-weight);
  letter-spacing: var(--body-default-letter-spacing);
  line-height: var(--body-default-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.sign-up .icon-eye-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}

.sign-up .icon-eye-instance {
  height: 24px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 24px !important;
}

.sign-up .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
left:  60%;
  position: absolute;
  top: 606px;
  width: 400px;
}

.sign-up .buttons-instance {
  align-self: stretch !important;
  color: white;
  background-color: #132524 !important;
  width: 100% !important;
}

.sign-up .design-component-instance-node {
  align-self: stretch !important;
  width: 100% !important;
}

.sign-up .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.sign-up .already-have-an {
  color: var(--texttertiary);
  font-family: var(--body-large-regular-font-family);
  font-size: var(--body-large-regular-font-size);
  font-style: var(--body-large-regular-font-style);
  font-weight: var(--body-large-regular-font-weight);
  letter-spacing: var(--body-large-regular-letter-spacing);
  line-height: var(--body-large-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-up .log-in {
  color: #3b3bec;
  font-family: var(--body-large-regular-font-family);
  font-size: var(--body-large-regular-font-size);
  font-style: var(--body-large-regular-font-style);
  font-weight: var(--body-large-regular-font-weight);
  letter-spacing: var(--body-large-regular-letter-spacing);
  line-height: var(--body-large-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
.buttons-instance {
  cursor: pointer;
}

.sign-up .group {
  height: 16px;
  left:  60%;
  position: absolute;
  top: 560px;
  width: 346px;
}

.log-in {
  cursor: pointer;
}

.helper-text-error {
  color: var(--textcolordanger) !important;
  font-family: var(--caption-small-font-family);
  font-size: var(--caption-small-font-size);
  font-style: var(--caption-small-font-style);
  font-weight: var(--caption-small-font-weight);
  left: 22px;
  letter-spacing: var(--caption-small-letter-spacing);
  line-height: var(--caption-small-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 322px;
}

.selector-class {
    border: none;
    border-color: #f94515;
    border-radius: 8px;
    height: 30%;
    left:  60%;
    position: absolute;
    top: 20%;
    width: 20%;
}
.top-marg{
  top: 30% !important;
}

.sporstman-button {
  display: flex;
  width: 400px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #DFDFDF;
  border: none;
  font-weight : bold ;
  font-size: 18px;
}
.sporstman-button:hover {
  cursor: pointer;
}

.is-smth-selected:hover {
  cursor: pointer;
}

.trainer-button {
  display: flex;
  width: 400px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 8px;
  margin-bottom: 50px;
  margin-top: 10px;
  border: none;
  font-weight : bold ;
  font-size: 18px;
}
.trainer-button:hover {
  cursor: pointer;
}

.continue-button {
  display: flex;
  width: 400px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #132524;
  color: white;
  border: none;
  font-weight : bold ;
  font-size: 18px;
}

.chosen-button {
  background-color: #5ba6a1 !important;
  color: white;
}

.selector-text {
  position: relative;
  top: 0% ;
  width: 100%;
}

.alert-text {
  color: red !important;
}

.mbot-30 {
  margin-bottom: 30px;
}

.register-input {
    border: 2px solid !important;
    height: 5%;
    width: 100%;
    border-color: #e0effe !important;
}

.success-mark{
  margin-left: 36%;
  margin-bottom: 10%;

}
.success-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


